/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Cascader, Drawer, Form, Input, Radio, Space, Table, message } from "antd"
import React, { useEffect, useMemo, useState } from "react"
import "./index.less"
import { OperableImage } from "@src/components/OperableImage"
import { cross_env } from "../../../../services/serviceOp"
import TextArea from "antd/es/input/TextArea"
import TencentMap from "./map"
import {
  getUrlByFileId,
  getWarehouseAdd,
  getWarehouseAddress,
  getWarehouseDelete,
  getWarehouseLatLon,
  getWarehouseList,
  getWarehouseUpdate,
} from "../../service"

export default function AddTicket(props: any) {
  const { cloudOpen, setCloudOpen, upDataTable, cityTreeList, dataList } = props
  const [form] = Form.useForm()
  const [fileList, setFileList] = useState<any>("")
  const [latLonList, setLatLonList] = useState<any>([])
  const [latAddress, setLatAddress] = useState<any>("")
  const [lotAddress, setLotAddress] = useState<any>("")

  const init = async () => {
    const data = cloudOpen.record
    const cityData = data?.district?.map((item) => parseInt(item.value))
    form.setFieldsValue({
      ...data,
      districtSr: cityData,
    })

    setLatLonList([
      {
        lat: data?.latitude || "",
        lon: data?.longitude || "",
        content: data?.warehouseName,
      },
    ])
    if (data?.pictureOssId) {
      let newVideoList = await handGetUrlByFileId(data?.pictureOssId)
      setFileList(newVideoList[0])
    }
  }

  const columns = [
    {
      title: "关联组织",
      dataIndex: "organizationName",
      key: "organizationName",
    },
    {
      title: "组织中名称",
      dataIndex: "receiverName",
      key: "receiverName",
    },
    {
      title: "联系人",
      dataIndex: "contract",
      key: "contract",
    },
    {
      title: "联系方式",
      dataIndex: "contractInfo",
      key: "contractInfo",
    },
    {
      title: "备注",
      dataIndex: "notes",
      key: "notes",
    },
  ]

  const handGetUrlByFileId = async (fileIdList) => {
    let result = await getUrlByFileId({ zoom: false, fileIds: fileIdList })?.then(
      (res) => res?.data?.map((item, index) => (fileIdList[index] ? item : undefined))
    )
    return result
  }
  const handMapLon = async (data: any) => {
    const params = {
      latitude: data[0],
      longitude: data[1],
    }
    const result: any = await getWarehouseAddress(params)
    if (result?.code == 200) {
      form.setFieldsValue({
        completeAddress: result?.data,
        longitude: data[1],
        latitude: data[0],
      })
    }
  }

  useMemo(async () => {
    if (cloudOpen?.open && cloudOpen?.type == "edit") {
      init()
    } else if (cloudOpen?.open && cloudOpen?.type == "add") {
      setLatLonList(dataList)
      form.setFieldValue("auth", true)
    }
  }, [cloudOpen])

  const handClear = () => {
    setLatAddress("")
    setLotAddress("")
    setFileList("")
    setCloudOpen({ open: false })
    form.resetFields()
  }

  return (
    <Drawer
      title={cloudOpen?.type == "add" ? "新增云仓" : "详情"}
      onClose={() => {
        handClear()
      }}
      destroyOnClose={true}
      open={cloudOpen?.open}
      width={1000}
      maskClosable={false}
      footer={
        <Space style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            {cloudOpen?.type == "edit" && (
              <Button
                danger
                onClick={async () => {
                  await getWarehouseDelete(cloudOpen?.record?.id)
                  message.success("删除成功")
                  setCloudOpen({ open: false })
                  upDataTable()
                  handClear()
                }}
              >
                删除
              </Button>
            )}
          </div>
          <div>
            <Button
              onClick={() => {
                handClear()
              }}
            >
              取消
            </Button>
            <Button
              onClick={async () => {
                await form.validateFields()
                const values = form.getFieldsValue()
                const params = {
                  ...values,
                }
                if (cloudOpen.type == "add") {
                  await getWarehouseAdd(params)
                  message.success("操作成功")
                  handClear()
                  // window.location.reload()
                  upDataTable()
                } else {
                  params.id = cloudOpen?.record?.id
                  await getWarehouseUpdate(params)
                  message.success("操作成功")
                  handClear()
                  // window.location.reload()
                  upDataTable()
                }
              }}
              type="primary"
              style={{ marginLeft: 8 }}
            >
              确定
            </Button>
          </div>
        </Space>
      }
    >
      <h4>基本信息</h4>
      <Form form={form}>
        <Form.Item label="名称" name="warehouseName">
          <Input placeholder="请输入" style={{ width: 500 }}></Input>
        </Form.Item>
        <Form.Item label="省市区" name="districtSr" rules={[{ required: true, message: "请选择" }]}>
          <Cascader
            style={{ width: 500 }}
            options={cityTreeList}
            placeholder="请选择"
            onChange={async (value, data) => {
              const _result = data?.map((ie) => {
                return {
                  label: ie?.label,
                  value: ie?.value,
                }
              })
              form.setFieldsValue({ district: _result })
              const cityData = data?.map((item) => item.label)
              const result: any = await getWarehouseLatLon({ address: cityData?.join("") })
              if (result.code == 200) {
                setLatAddress(result.data.latitude)
                setLotAddress(result.data.longitude)
              }
            }}
            allowClear
            showSearch
          />
        </Form.Item>
        <Form.Item label="省市区" name="district" hidden>
          <Input></Input>
        </Form.Item>
        <Form.Item
          label="详细地址"
          name="completeAddress"
          rules={[{ required: true, message: "请输入" }]}
        >
          <Input placeholder="请输入或点击地图选择地址" style={{ width: 500 }}></Input>
        </Form.Item>
        <Form.Item label="地图选点" style={{ width: 620 }}>
          <div style={{ marginTop: 10 }}>
            <TencentMap
              idName={`map_${1}`}
              lat={latAddress}
              lon={lotAddress}
              latLonList={latLonList}
              handMapLon={handMapLon}
            />
          </div>
        </Form.Item>

        <div style={{ display: "flex" }}>
          <Form.Item label="经度" name="longitude" rules={[{ required: true, message: "请输入" }]}>
            <Input placeholder="请输入" style={{ width: 215 }}></Input>
          </Form.Item>
          <Form.Item
            style={{ marginLeft: -50 }}
            label="纬度"
            name="latitude"
            rules={[{ required: true, message: "请输入" }]}
          >
            <Input placeholder="请输入" style={{ width: 215 }}></Input>
          </Form.Item>
        </div>
        <Form.Item label="认证状态" name="auth" rules={[{ required: true, message: "请选择" }]}>
          <Radio.Group name="radiogroup">
            <Radio value={true}>认证</Radio>
            <Radio value={false}>非认证</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name={"pictureOssId"}
          label={"照片"}
          rules={[{ required: true, message: "请上传" }]}
        >
          <OperableImage
            width={120}
            height={120}
            action={`${cross_env}/rscm/rscm-resource/oss/endpoint`}
            src={fileList || ""}
            uploadFileProps={{
              name: "file",
              accept: "image/png,image/jpeg",
              onChange: async ({ fileList }) => {
                if (!fileList.some((file) => file.status !== "done")) {
                  form?.setFieldValue("pictureOssId", fileList[0].response?.data?.filePath)
                  setFileList(fileList[0]?.response?.data?.downloadLink)
                }
              },
            }}
          >
            上传
          </OperableImage>
        </Form.Item>
        <Form.Item label="备注" name="notes" style={{ marginBottom: 24 }}>
          <TextArea placeholder="请填写备注" style={{ width: 500 }}></TextArea>
        </Form.Item>
        {cloudOpen?.type == "edit" && (
          <>
            <h4>关联组织</h4>
            <div className="ticket-form">
              <Table
                style={{ marginTop: 10 }}
                pagination={false}
                dataSource={cloudOpen?.record?.warehouseOrganizations}
                columns={columns}
                rowKey={(record: any) => record?.index}
              ></Table>
            </div>
          </>
        )}
      </Form>
    </Drawer>
  )
}
